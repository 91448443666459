/*
 * @Author: 喀喀喀 1246444836@qq.com
 * @Date: 2022-07-19 15:42:32
 * @LastEditors: 喀喀喀 1246444836@qq.com
 * @LastEditTime: 2022-07-19 15:57:42
 * @FilePath: /xinxihoutai/src/utils/notice.js
 * @Description: 公告内容的编辑器配置信息
 */
const editorOption ={
    modules: {
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike"],
                ["blockquote", "code-block"],
                [{
                        header: 1,
                    },
                    {
                        header: 2,
                    },
                ],
                [
                    {
                        list: "ordered",
                    },
                    {
                        list: "bullet",
                    },
                ],
                [
                    {
                        script: "sub",
                    },
                    {
                        script: "super",
                    },
                ],
                [
                    {
                        indent: "-1",
                    },
                    {
                        indent: "+1",
                    },
                ],
                [
                    {
                        direction: "rtl",
                    },
                ],
                [
                    {
                        size: ["small", false, "large", "huge"],
                    },
                ],
                [
                    {
                        header: [1, 2, 3, 4, 5, 6, false],
                    },
                ],
                [
                    {
                        color: [],
                    },
                    {
                        background: [],
                    },
                ],
                [
                    {
                        font: [],
                    },
                ],
                [
                    {
                        align: [],
                    },
                ],
                ["clean"],
                ["image"],
            ],
            handlers: {
                image: function (value) {
                    console.log(value);
                    if (value) {
                        document.querySelector("#upload").click();
                    }
                },
            },
        },
        imageResize: {
            displayStyles: {
                backgroundColor: "black",
                border: "none",
                color: "white",
            },
        },
    },
    placeholder: "请输入公告内容",
}

export default editorOption